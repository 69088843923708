@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: rgb(24, 24, 24);
  color: #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
}
body #root {
  position: relative;
  display: flex;
  justify-content: center;
}
body #root .App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-black {
  background-color: black;
}

@keyframes mailhint {
  0% {
    height: 0;
  }
  10% {
    height: 90px;
    background-color: #fff;
    padding: 10px;
  }
  90% {
    height: 90px;
    background-color: #fff;
    padding: 10px;
  }
  100% {
    height: 0;
  }
}
header {
  width: 100%;
  position: fixed;
  top: 20px;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}
header .header-container {
  width: 100%;
  transition-property: width, padding, background-color;
  transition-timing-function: ease-in-out;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
}
header .header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: calc(2em* 0.5) 10em;
  transition-property: color, font-weight, background-color, padding;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  position: relative;
}
@keyframes font-color {
  0% {
    color: transparent;
  }
  50% {
    color: #000;
  }
  100% {
    color: #000;
  }
}
header .header-box button {
  background: none;
  border: none;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  z-index: 3;
  width: 57px;
}
header .header-box #menu-btn-mobile {
  display: none;
}
header .header-box .menu-box {
  position: absolute;
  background-color: #fff;
  width: 398px;
  color: #000;
  right: 0;
  top: 0;
  transition: all 0.3s;
}
header .header-box .menu-box .menu-section {
  border-top: 1px solid rgb(196, 196, 196);
}
header .header-box .menu-box .menu-section h2 {
  padding: 20px;
  cursor: pointer;
  width: 100%;
  color: #000;
}
header .header-box .menu-box .menu-section .menu-section-items {
  transition: all 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  height: 0;
}
header .header-box .menu-box .menu-section .menu-section-items p,
header .header-box .menu-box .menu-section .menu-section-items a {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  font-weight: 300;
  color: #000;
}
header .header-box .menu-box .menu-section .menu-section-items p .icon,
header .header-box .menu-box .menu-section .menu-section-items a .icon {
  height: 20px;
  width: 20px;
  transform: translateY(4px);
}
header .header-box .menu-box .menu-section .menu-section-items p:hover,
header .header-box .menu-box .menu-section .menu-section-items a:hover {
  font-weight: 700;
}
header .header-box .menu-box .menu-section .projects-opened {
  height: 360px;
}
header .header-box .menu-box .contact .menu-section-items {
  height: 160px;
}
header .header-box .menu-box .menu-section:first-child {
  margin-top: 100px;
}
header .header-box .hidden {
  width: 0;
  overflow: hidden;
}
header .header-box .visible h2 {
  animation: font-color;
  animation-duration: 2s;
  animation-iteration-count: 1;
}
header .header-box .black-text {
  color: #000;
}
header #logo-box {
  height: 35px;
  width: 35px;
  cursor: pointer;
}
header .mailbox {
  position: relative;
}
header .mailbox a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
header .mailbox .mailbox-info,
header .mailbox .mailbox-info-2,
header .mailbox .mailbox-info-3 {
  position: absolute;
  top: 100%;
  text-align: center;
  font-size: 11px;
  color: #000;
  font-weight: 700;
  overflow: hidden;
  height: 0;
  transition: all 0.3s;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .mailbox .mailbox-info {
  border-radius: 50%;
  left: 100%;
}
header .mailbox .mailbox-info-2 {
  border-radius: 50%;
  right: 100%;
}
header .mailbox .mailbox-info-3 {
  top: 40px;
  transform: translateX(-50%);
  border-radius: 10px;
  left: 50%;
}
header .mailbox .visible-info {
  height: 20px;
  padding: 10px;
  background-color: #fff;
}
header .mailbox:hover .mailbox-info {
  animation: mailhint;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
header .mailbox:hover .mailbox-info-2 {
  animation: mailhint;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-delay: 1s;
}
header .header-background {
  background-color: rgb(255, 255, 255);
}
header .header-background a,
header .header-background button {
  color: #000;
  font-weight: 700;
}

@keyframes exp-animation-slider {
  0% {
    width: 0;
  }
  33% {
    width: 0;
  }
  66% {
    width: 0;
  }
  100% {
    width: 98%;
  }
}
@keyframes exp-animation-circle {
  0% {
    top: 37%;
    width: 0;
    height: 0;
  }
  50% {
    top: 37%;
    width: 0;
    height: 0;
  }
  100% {
    top: 25%;
    width: 25px;
    height: 25px;
  }
}
@keyframes exp-animation-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scroll-arrow-bounce {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(0, 10px);
  }
  10% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, 10px);
  }
  20% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  55% {
    transform: translate(0, 10px);
  }
  60% {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, 10px);
  }
  70% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
#home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#home-page #hero {
  margin-top: 250px;
  height: 400px;
  width: 100%;
}
#home-page #hero div {
  height: 100%;
  width: 100%;
}
#home-page #hero div:nth-child(2),
#home-page #hero div:nth-child(3) {
  display: none;
}
#home-page .scroll-btn-box {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 100px;
  transition: all 0.3s;
}
#home-page .scroll-btn-box button {
  animation: scroll-arrow-bounce 5s infinite;
  height: 50px;
  width: 50px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
#home-page .scroll-btn-box button svg {
  height: 100%;
  width: 100%;
  color: #fff;
}
#home-page .hide {
  visibility: hidden;
}
#home-page #home-page-container h1 {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 48px;
}
#home-page #home-page-container #portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: minmax(auto, auto);
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 48px;
  grid-template-areas: "box1 box2 box3" "box1 box2 box3" "box4 box4 box5" "box4 box4 box5" "box7 box7 box7" "box6 box6 box6" "box6 box6 box6" "box8 box9 box10" "box8 box9 box10" "box11 box11 box11" "box11 box11 box11" "box12 box12 box12" "box13 box13 box13";
}
#home-page #home-page-container #portfolio-container .project-box {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
}
#home-page #home-page-container #portfolio-container .project-box .project-box-content {
  cursor: pointer;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
#home-page #home-page-container #portfolio-container .project-box .project-box-content-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: flex-end;
  padding: 2em;
}
#home-page #home-page-container #portfolio-container .project-box .project-box-content-mask h3 {
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}
#home-page #home-page-container #portfolio-container .project-box .project-box-content-mask p {
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
}
#home-page #home-page-container #portfolio-container .project-box:hover .project-box-content {
  transform: scale(1.2);
}
#home-page #home-page-container #portfolio-container .project-box:hover .project-box-content-mask {
  opacity: 1;
}
#home-page #home-page-container #portfolio-container .project-tile {
  height: 478px;
}
#home-page #home-page-container #portfolio-container .text {
  background-color: transparent;
}
#home-page #home-page-container #portfolio-container #box-1 {
  grid-area: box1;
}
#home-page #home-page-container #portfolio-container #box-1 .project-box-content {
  background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
}
#home-page #home-page-container #portfolio-container #box-2 {
  grid-area: box2;
}
#home-page #home-page-container #portfolio-container #box-2 .project-box-content {
  background-image: url("./Resources/proj-chicken/cc_kafel.jpg");
}
#home-page #home-page-container #portfolio-container #box-3 {
  grid-area: box3;
  background-image: url("./Resources/kafel_test.jpg");
}
#home-page #home-page-container #portfolio-container #box-4 {
  grid-area: box4;
  background-image: url("./Resources/proj-animation/animowany_bear_kafel.gif");
}
#home-page #home-page-container #portfolio-container #box-5 {
  grid-area: box5;
}
#home-page #home-page-container #portfolio-container #box-5 .project-box-content {
  background-image: url("./Resources/proj-zawolcze/kafel_zawolcze.png");
}
#home-page #home-page-container #portfolio-container #box-6 {
  grid-area: box6;
}
#home-page #home-page-container #portfolio-container #box-6 .project-box-content {
  background-image: url("./Resources/proj-drawings/drawings_main02.jpg");
}
#home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask h3 {
  margin-left: 238px;
  margin-bottom: 32px;
}
#home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask h3 span {
  visibility: hidden;
}
#home-page #home-page-container #portfolio-container #box-7 {
  grid-area: box7;
  padding: 0;
}
#home-page #home-page-container #portfolio-container #box-7 p span:nth-child(1) {
  text-transform: uppercase;
  font-size: 64px;
  font-weight: 300;
  letter-spacing: 3px;
}
#home-page #home-page-container #portfolio-container #box-7 p span:nth-child(2) {
  font-weight: 700;
  font-size: 30px;
}
#home-page #home-page-container #portfolio-container #box-7 p {
  font-size: 30px;
  font-weight: 700;
}
#home-page #home-page-container #portfolio-container #box-8 {
  grid-area: box8;
  background-image: url("./Resources/kafel_idea.jpg");
}
#home-page #home-page-container #portfolio-container #box-9 {
  grid-area: box9;
}
#home-page #home-page-container #portfolio-container #box-9 .project-box-content {
  background-image: url("./Resources/proj-magazyn/398x478_mw_tytulowy.jpg");
}
#home-page #home-page-container #portfolio-container #box-10 {
  grid-area: box10;
}
#home-page #home-page-container #portfolio-container #box-10 .project-box-content {
  background-image: url("./Resources/proj-farcar/398x478.jpg");
}
#home-page #home-page-container #portfolio-container #box-11 {
  grid-area: box11;
}
#home-page #home-page-container #portfolio-container #box-11 .project-box-content {
  background-image: url("./Resources/proj-farba/kafel_plamoodporna.jpg");
}
#home-page #home-page-container #portfolio-container #box-12 {
  grid-row: 100px;
  grid-area: box12;
  height: 80px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
#home-page #home-page-container #portfolio-container #box-12 div p {
  font-size: 30px;
  display: inline-block;
}
#home-page #home-page-container #portfolio-container #box-12 div span {
  display: inline-block;
  font-size: 25px;
  margin-left: 20px;
}
#home-page #home-page-container #portfolio-container #box-12:hover {
  background-color: #fff;
}
#home-page #home-page-container #portfolio-container #box-12:hover div p,
#home-page #home-page-container #portfolio-container #box-12:hover div span {
  color: #000;
}
#home-page #home-page-container #portfolio-container #box-13 {
  grid-area: box13;
  padding: 0;
}
#home-page #home-page-container #portfolio-container #box-13 h2 {
  font-size: 64px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 50px;
}
#home-page #home-page-container #portfolio-container #box-13 div {
  display: flex;
}
#home-page #home-page-container #portfolio-container #box-13 div p {
  width: 50%;
}
#home-page #home-page-container #portfolio-container #box-13 .slider {
  position: relative;
  height: 60px;
  width: 100%;
}
#home-page #home-page-container #portfolio-container #box-13 .slider .circle,
#home-page #home-page-container #portfolio-container #box-13 .slider .circle-2,
#home-page #home-page-container #portfolio-container #box-13 .slider .circle-3 {
  position: absolute;
  left: 0%;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0;
  top: 25%;
}
#home-page #home-page-container #portfolio-container #box-13 .slider .circle-2 {
  left: 33%;
}
#home-page #home-page-container #portfolio-container #box-13 .slider .circle-3 {
  left: 66%;
}
#home-page #home-page-container #portfolio-container #box-13 .slider .line {
  top: 45%;
  width: 98%;
  position: absolute;
  background-color: #fff;
  height: 2px;
  margin: 0;
}
#home-page #home-page-container #portfolio-container #box-13 .bolded-row {
  font-weight: 700;
  font-size: 30px;
}
#home-page #home-page-container #portfolio-container #box-13 .detail-row {
  font-size: 16px;
  font-weight: 300;
}
#home-page #home-page-container #portfolio-container #box-13 .detail-row p {
  padding-left: 22px;
}
#home-page #home-page-container #portfolio-container .exp {
  opacity: 0;
}
#home-page #home-page-container #portfolio-container .exp-animated {
  opacity: 1;
}
#home-page #home-page-container #portfolio-container .exp-animated .line {
  animation: exp-animation-slider;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
}
#home-page #home-page-container #portfolio-container .exp-animated .circle,
#home-page #home-page-container #portfolio-container .exp-animated .circle-2,
#home-page #home-page-container #portfolio-container .exp-animated .circle-3 {
  animation: exp-animation-circle;
  animation-duration: 750ms;
  animation-iteration-count: 1;
}
#home-page #home-page-container #portfolio-container .exp-animated .detail-row,
#home-page #home-page-container #portfolio-container .exp-animated .bolded-row {
  animation: exp-animation-text;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.project-view {
  width: 1226px;
  padding-top: 10em;
}
.project-view h2 {
  text-transform: uppercase;
  font-size: 64px;
  font-weight: 300;
}
.project-view .project-view-container {
  display: grid;
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 48px;
}
.project-view .project-view-container .project-view-tile {
  background-position: center;
  background-size: cover;
}
.project-view .project-view-container .text {
  background-color: transparent;
  display: flex;
}
.project-view .project-view-container .text h3 {
  width: 400px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
}
.project-view .proj-kaseta {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box3 box3" "box4 box5 box6" "box7 box7 box7" "box8 box8 box9" "box10 box11 box12";
}
.project-view .proj-kaseta .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .proj-kaseta .project-view-tile .animation {
  width: 100%;
  height: 100%;
}
.project-view .proj-kaseta .project-view-tile a {
  color: #fff;
  font-size: 16px;
}
.project-view .proj-kaseta #view-tile-1 {
  background-image: url("./Resources/proj-kaseta/kaseta_headline.jpg");
  height: 655px;
  grid-area: box1;
}
.project-view .proj-kaseta #view-tile-2 {
  min-height: 200px;
  grid-area: box2;
  background-color: transparent;
  font-size: 25px;
}
.project-view .proj-kaseta #view-tile-2 span {
  font-weight: 700;
}
.project-view .proj-kaseta #view-tile-3 {
  height: 655px;
  grid-area: box3;
}
.project-view .proj-kaseta #view-tile-4 {
  background-image: url("./Resources/proj-kaseta/398x478.jpg");
  height: 478px;
  grid-area: box4;
}
.project-view .proj-kaseta #view-tile-5 {
  background-image: url("./Resources/proj-kaseta/398x478_bluzal.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .proj-kaseta #view-tile-6 {
  background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
  height: 478px;
  grid-area: box6;
}
.project-view .proj-kaseta #view-tile-7 {
  background-image: url("./Resources/proj-kaseta/1226x655.jpg");
  height: 655px;
  grid-area: box7;
}
.project-view .proj-kaseta #view-tile-8 {
  background-image: url("./Resources/proj-kaseta/812x478_fale.jpg");
  height: 478px;
  grid-area: box8;
}
.project-view .proj-kaseta #view-tile-9 {
  background-image: url("./Resources/proj-kaseta/398x478_girl.jpg");
  height: 478px;
  grid-area: box9;
}
.project-view .proj-kaseta #view-tile-10 {
  background-image: url("./Resources/proj-kaseta/348x478education.jpg");
  height: 478px;
  grid-area: box10;
}
.project-view .proj-kaseta #view-tile-11 {
  background-image: url("./Resources/proj-kaseta/348x478art.jpg");
  height: 478px;
  grid-area: box11;
}
.project-view .proj-kaseta #view-tile-12 {
  background-image: url("./Resources/proj-kaseta/348x478_kierunkowskaz.jpg");
  height: 478px;
  grid-area: box12;
}
.project-view .proj-kaseta .tile-visible {
  opacity: 1;
}
.project-view .chickens-project {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box3 box3" "box4 box4 box4" "box5 box6 box7" "box8 box8 box8" "box9 box9 box9" "box10 box10 box10";
}
.project-view .chickens-project .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .chickens-project #view-tile-1 {
  background-image: url("./Resources/proj-chicken/1cc_headline_tytułowy.jpg");
  height: 547px;
  grid-area: box1;
}
.project-view .chickens-project #view-tile-2 {
  min-height: 200px;
  grid-area: box2;
  font-size: 25px;
}
.project-view .chickens-project #view-tile-2 div p:first-child,
.project-view .chickens-project #view-tile-2 span {
  font-weight: 700;
}
.project-view .chickens-project #view-tile-3 {
  background-image: url("./Resources/proj-chicken/2cc_wide.jpg");
  height: 655px;
  grid-area: box3;
}
.project-view .chickens-project #view-tile-4 {
  background-image: url("./Resources/proj-chicken/3cc_koszulki_1226x655.jpg");
  height: 655px;
  grid-area: box4;
}
.project-view .chickens-project #view-tile-5 {
  background-image: url("./Resources/proj-chicken/4cc_black.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .chickens-project #view-tile-6 {
  background-image: url("./Resources/proj-chicken/5kafel_boxy.jpg");
  height: 478px;
  grid-area: box6;
}
.project-view .chickens-project #view-tile-7 {
  background-image: url("./Resources/proj-chicken/6cc_yellow.jpg");
  height: 478px;
  grid-area: box7;
}
.project-view .chickens-project #view-tile-8 {
  background-image: url("./Resources/proj-chicken/7-1226x655_truck.jpg");
  height: 655px;
  grid-area: box8;
}
.project-view .chickens-project #view-tile-9 {
  background-image: url("./Resources/proj-chicken/8cc_fartuch_1226x655.jpg");
  height: 655px;
  grid-area: box9;
}
.project-view .chickens-project #view-tile-10 {
  background-image: url("./Resources/proj-chicken/9cc_wlepka_1226x655.jpg");
  height: 655px;
  grid-area: box10;
}
.project-view .chickens-project .tile-visible {
  opacity: 1;
}
.project-view .proj-zawolcze {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box3 box3" "box4 box5 box6" "box7 box7 box8" "box9 box9 box9" "box10 box11 box11" "box12 box12 box13" "box14 box15 box15" "box16 box16 box16";
}
.project-view .proj-zawolcze .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .proj-zawolcze #view-tile-1 {
  background-image: url("./Resources/proj-zawolcze/1226x687_headline_ryby.jpg");
  height: 547px;
  grid-area: box1;
}
.project-view .proj-zawolcze #view-tile-2 {
  min-height: 300px;
  grid-area: box2;
  font-size: 25px;
}
.project-view .proj-zawolcze #view-tile-2 div span {
  font-weight: 700;
}
.project-view .proj-zawolcze #view-tile-2 div p:first-child {
  margin-bottom: 2em;
}
.project-view .proj-zawolcze #view-tile-3 {
  background-image: url("./Resources/proj-zawolcze/1226x655_logo_01.jpg");
  height: 655px;
  grid-area: box3;
}
.project-view .proj-zawolcze #view-tile-4 {
  background-image: url("./Resources/proj-zawolcze/398x478_02.jpg");
  height: 478px;
  grid-area: box4;
}
.project-view .proj-zawolcze #view-tile-5 {
  background-image: url("./Resources/proj-zawolcze/398x47805.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .proj-zawolcze #view-tile-6 {
  background-image: url("./Resources/proj-zawolcze/398x478_03.jpg");
  height: 478px;
  grid-area: box6;
}
.project-view .proj-zawolcze #view-tile-7 {
  background-image: url("./Resources/proj-zawolcze/812x478_opakowania_c.jpg");
  height: 478px;
  grid-area: box7;
}
.project-view .proj-zawolcze #view-tile-8 {
  background-image: url("./Resources/proj-zawolcze/398x47801_c.jpg");
  height: 478px;
  grid-area: box8;
}
.project-view .proj-zawolcze #view-tile-9 {
  background-image: url("./Resources/proj-zawolcze/1226x655.jpg");
  height: 655px;
  grid-area: box9;
}
.project-view .proj-zawolcze #view-tile-10 {
  background-image: url("./Resources/proj-zawolcze/398x478_wedzony.jpg");
  height: 478px;
  grid-area: box10;
}
.project-view .proj-zawolcze #view-tile-11 {
  background-image: url("./Resources/proj-zawolcze/812x478_paprykarz.jpg");
  height: 478px;
  grid-area: box11;
}
.project-view .proj-zawolcze #view-tile-12 {
  background-image: url("./Resources/proj-zawolcze/812x478_stoisko.jpg");
  height: 478px;
  grid-area: box12;
}
.project-view .proj-zawolcze #view-tile-13 {
  background-image: url("./Resources/proj-zawolcze/398x478_stoisko.jpg");
  height: 478px;
  grid-area: box13;
}
.project-view .proj-zawolcze #view-tile-14 {
  background-image: url("./Resources/proj-zawolcze/348x478_insta.jpg");
  height: 478px;
  grid-area: box14;
}
.project-view .proj-zawolcze #view-tile-15 {
  background-image: url("./Resources/proj-zawolcze/812x478_komp.jpg");
  height: 478px;
  grid-area: box15;
}
.project-view .proj-zawolcze #view-tile-16 {
  background-image: url("./Resources/proj-zawolcze/stawy.png");
  height: 655px;
  grid-area: box16;
}
.project-view .proj-zawolcze .tile-visible {
  opacity: 1;
}
.project-view .drawings-gallery {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box4 box5" "box6 box6 box6" "box7 box8 box9" "box10 box11 box12" "box13 box14 box15" "box16 box17 box18" "box19 box19 box20";
}
.project-view .drawings-gallery .project-view-tile {
  height: 478px;
  opacity: 0;
  transition: all 0.5s;
}
.project-view .drawings-gallery .project-view-tile .drawing-mask {
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.project-view .drawings-gallery .content:hover {
  cursor: pointer;
}
.project-view .drawings-gallery .content:hover .drawing-mask {
  opacity: 1;
}
.project-view .drawings-gallery #view-tile-1 {
  background-image: url("./Resources/proj-drawings/drawings_main_white.jpg");
  grid-area: box1;
}
.project-view .drawings-gallery #view-tile-2 {
  grid-area: box2;
  font-size: 25px;
  height: auto;
}
.project-view .drawings-gallery #view-tile-2 div p:first-child {
  margin-bottom: 2em;
}
.project-view .drawings-gallery #view-tile-3 {
  background-image: url("./Resources/proj-drawings/girl.jpg");
  grid-area: box3;
}
.project-view .drawings-gallery #view-tile-4 {
  background-image: url("./Resources/proj-drawings/p-blinders.jpg");
  grid-area: box4;
}
.project-view .drawings-gallery #view-tile-5 {
  background-image: url("./Resources/proj-drawings/wolf.jpg");
  grid-area: box5;
}
.project-view .drawings-gallery #view-tile-6 {
  background-image: url("./Resources/proj-drawings/dragon.jpg");
  grid-area: box6;
}
.project-view .drawings-gallery #view-tile-7 {
  background-image: url("./Resources/proj-drawings/faun.jpg");
  grid-area: box7;
}
.project-view .drawings-gallery #view-tile-8 {
  background-image: url("./Resources/proj-drawings/flowers.jpg");
  grid-area: box8;
}
.project-view .drawings-gallery #view-tile-9 {
  background-image: url("./Resources/proj-drawings/eagle.jpg");
  grid-area: box9;
}
.project-view .drawings-gallery #view-tile-10 {
  background-image: url("./Resources/proj-drawings/grzybek.jpg");
  grid-area: box10;
}
.project-view .drawings-gallery #view-tile-11 {
  background-image: url("./Resources/proj-drawings/motyl.jpg");
  grid-area: box11;
}
.project-view .drawings-gallery #view-tile-12 {
  background-image: url("./Resources/proj-drawings/panda.jpg");
  grid-area: box12;
}
.project-view .drawings-gallery #view-tile-13 {
  background-image: url("./Resources/proj-drawings/face01.jpg");
  grid-area: box13;
}
.project-view .drawings-gallery #view-tile-14 {
  background-image: url("./Resources/proj-drawings/root.jpg");
  grid-area: box14;
}
.project-view .drawings-gallery #view-tile-15 {
  background-image: url("./Resources/proj-drawings/rzezba.jpg");
  grid-area: box15;
}
.project-view .drawings-gallery #view-tile-16 {
  background-image: url("./Resources/proj-drawings/tiger.jpg");
  grid-area: box16;
}
.project-view .drawings-gallery #view-tile-17 {
  background-image: url("./Resources/proj-drawings/war.jpg");
  grid-area: box17;
}
.project-view .drawings-gallery #view-tile-18 {
  background-image: url("./Resources/proj-drawings/face02.jpg");
  grid-area: box18;
}
.project-view .drawings-gallery #view-tile-19 {
  background-image: url("./Resources/proj-drawings/rubicon_mojewww.jpg");
  grid-area: box19;
}
.project-view .drawings-gallery #view-tile-20 {
  background-image: url("./Resources/proj-drawings/zamek_hauru.jpg");
  grid-area: box20;
}
.project-view .drawings-gallery .tile-visible {
  opacity: 1;
}
.project-view .animations {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box3 box3" "box4 box4 box4" "box5 box5 box5" "box6 box6 box6" "box7 box7 box7";
}
.project-view .animations .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .animations .project-view-tile .animation {
  width: 100%;
  height: 100%;
}
.project-view .animations .project-view-tile a {
  color: #fff;
  font-size: 16px;
}
.project-view .animations #view-tile-1 {
  height: 655px;
  grid-area: box1;
}
.project-view .animations #view-tile-2 {
  min-height: 100px;
  grid-area: box2;
  background-color: transparent;
  font-size: 25px;
}
.project-view .animations #view-tile-2 div p:first-child,
.project-view .animations #view-tile-2 span {
  font-weight: 700;
}
.project-view .animations #view-tile-3 {
  height: 655px;
  grid-area: box3;
}
.project-view .animations #view-tile-4 {
  height: 655px;
  grid-area: box4;
}
.project-view .animations #view-tile-5 {
  height: 690px;
  grid-area: box5;
}
.project-view .animations #view-tile-6 {
  height: 690px;
  grid-area: box6;
}
.project-view .animations #view-tile-7 {
  height: 690px;
  grid-area: box7;
}
.project-view .animations .tile-visible {
  opacity: 1;
}
.project-view .proj-magazyn {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box3 box3" "box4 box4 box4" "box5 box6 box7" "box8 box8 box8" "box9 box9 box10" "box11 box11 box11";
}
.project-view .proj-magazyn .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .proj-magazyn .project-view-tile .animation {
  width: 100%;
  height: 100%;
}
.project-view .proj-magazyn .project-view-tile a {
  color: #fff;
  font-size: 16px;
}
.project-view .proj-magazyn #view-tile-1 {
  background-image: url("./Resources/proj-magazyn/1226x547_headline.jpg");
  height: 547px;
  grid-area: box1;
}
.project-view .proj-magazyn #view-tile-2 {
  min-height: 200px;
  grid-area: box2;
  background-color: transparent;
  font-size: 25px;
}
.project-view .proj-magazyn #view-tile-2 span {
  font-weight: 700;
}
.project-view .proj-magazyn #view-tile-3 {
  height: 546px;
  grid-area: box3;
}
.project-view .proj-magazyn #view-tile-4 {
  background-image: url("./Resources/proj-magazyn/1226x655_szkice_twarzy.jpg");
  height: 655px;
  grid-area: box4;
}
.project-view .proj-magazyn #view-tile-5 {
  background-image: url("./Resources/proj-magazyn/398x478_wizytowka.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .proj-magazyn #view-tile-6 {
  background-image: url("./Resources/proj-magazyn/398x478_szyld.jpg");
  height: 478px;
  grid-area: box6;
}
.project-view .proj-magazyn #view-tile-7 {
  background-image: url("./Resources/proj-magazyn/398x478_wizytowka02.jpg");
  height: 478px;
  grid-area: box7;
}
.project-view .proj-magazyn #view-tile-8 {
  background-image: url("./Resources/proj-magazyn/1226x655_01.jpg");
  height: 655px;
  grid-area: box8;
}
.project-view .proj-magazyn #view-tile-9 {
  background-image: url("./Resources/proj-magazyn/812x478_wiz_www.jpg");
  height: 478px;
  grid-area: box9;
}
.project-view .proj-magazyn #view-tile-10 {
  background-image: url("./Resources/proj-magazyn/398x478_03.jpg");
  height: 478px;
  grid-area: box10;
}
.project-view .proj-magazyn #view-tile-11 {
  background-image: url("./Resources/proj-magazyn/1226x655_02.jpg");
  height: 655px;
  grid-area: box11;
}
.project-view .proj-magazyn .tile-visible {
  opacity: 1;
}
.project-view .proj-farba {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box4 box5" "box6 box6 box6" "box7 box8 box9" "box10 box10 box10" "box11 box11 box12" "box13 box13 box13" "box14 box14 box14";
}
.project-view .proj-farba .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .proj-farba #view-tile-1 {
  background-image: url("./Resources/proj-farba/1226x687_headline_plamoodporna.jpg");
  height: 547px;
  grid-area: box1;
}
.project-view .proj-farba #view-tile-2 {
  min-height: 300px;
  grid-area: box2;
  font-size: 25px;
}
.project-view .proj-farba #view-tile-2 div p:first-child,
.project-view .proj-farba #view-tile-2 span {
  font-weight: 700;
}
.project-view .proj-farba #view-tile-3 {
  background-image: url("./Resources/proj-farba/398x478_energ.jpg");
  height: 478px;
  grid-area: box3;
}
.project-view .proj-farba #view-tile-4 {
  background-image: url("./Resources/proj-farba/398x478_kreatywna.jpg");
  height: 478px;
  grid-area: box4;
}
.project-view .proj-farba #view-tile-5 {
  background-image: url("./Resources/proj-farba/398x478_odkrywcza.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .proj-farba #view-tile-6 {
  background-image: url("./Resources/proj-farba/1226x655_plamoodp_site.jpg");
  height: 655px;
  grid-area: box6;
}
.project-view .proj-farba #view-tile-7 {
  background-image: url("./Resources/proj-farba/398x478_subtelna.jpg");
  height: 478px;
  grid-area: box7;
}
.project-view .proj-farba #view-tile-8 {
  background-image: url("./Resources/proj-farba/398x478_taktowna.jpg");
  height: 478px;
  grid-area: box8;
}
.project-view .proj-farba #view-tile-9 {
  background-image: url("./Resources/proj-farba/398x478_zjawiskowa.jpg");
  height: 478px;
  grid-area: box9;
}
.project-view .proj-farba #view-tile-10 {
  background-image: url("./Resources/proj-farba/1226x655_plamoodp_busstop02.jpg");
  height: 655px;
  grid-area: box10;
}
.project-view .proj-farba #view-tile-11 {
  background-image: url("./Resources/proj-farba/812x478_plamoodp_gazeta.jpg");
  height: 478px;
  grid-area: box11;
}
.project-view .proj-farba #view-tile-12 {
  background-image: url("./Resources/proj-farba/398x478_plamoodp03.jpg");
  height: 478px;
  grid-area: box12;
}
.project-view .proj-farba #view-tile-13 {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: box13;
}
.project-view .proj-farba #view-tile-13 video {
  width: 100%;
}
.project-view .proj-farba #view-tile-14 {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: box14;
}
.project-view .proj-farba #view-tile-14 video {
  width: 100%;
}
.project-view .proj-farba .tile-visible {
  opacity: 1;
}
.project-view .proj-farcar {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas: "box1 box1 box1" "box2 box2 box2" "box3 box4 box5" "box6 box6 box7" "box8 box9 box10" "box11 box12 box13" "box14 box15 box16" "box17 box17 box17";
}
.project-view .proj-farcar .project-view-tile {
  opacity: 0;
  transition: all 0.5s;
}
.project-view .proj-farcar #view-tile-1 {
  background-image: url("./Resources/proj-farcar/headline.jpg");
  height: 655px;
  grid-area: box1;
}
.project-view .proj-farcar #view-tile-2 {
  min-height: 300px;
  grid-area: box2;
  background-color: transparent;
  font-size: 25px;
}
.project-view .proj-farcar #view-tile-2 span {
  font-weight: 700;
}
.project-view .proj-farcar #view-tile-3 {
  background-image: url("./Resources/proj-farcar/fc_01.jpg");
  height: 478px;
  grid-area: box3;
}
.project-view .proj-farcar #view-tile-4 {
  height: 478px;
  grid-area: box4;
}
.project-view .proj-farcar #view-tile-5 {
  background-image: url("./Resources/proj-farcar/fc_02.jpg");
  height: 478px;
  grid-area: box5;
}
.project-view .proj-farcar #view-tile-6 {
  background-image: url("./Resources/proj-farcar/812x478_fc.jpg");
  height: 478px;
  grid-area: box6;
}
.project-view .proj-farcar #view-tile-7 {
  background-image: url("./Resources/proj-farcar/398x478_02.jpg");
  height: 478px;
  grid-area: box7;
}
.project-view .proj-farcar #view-tile-8 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/app_frames.jpg");
  height: 478px;
  grid-area: box8;
}
.project-view .proj-farcar #view-tile-9 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/02.jpg");
  height: 478px;
  grid-area: box9;
}
.project-view .proj-farcar #view-tile-10 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/03.jpg");
  height: 478px;
  grid-area: box10;
}
.project-view .proj-farcar #view-tile-11 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/04.jpg");
  height: 478px;
  grid-area: box11;
}
.project-view .proj-farcar #view-tile-12 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/05.jpg");
  height: 478px;
  grid-area: box12;
}
.project-view .proj-farcar #view-tile-13 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/06.jpg");
  height: 478px;
  grid-area: box13;
}
.project-view .proj-farcar #view-tile-14 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/07.jpg");
  height: 478px;
  grid-area: box14;
}
.project-view .proj-farcar #view-tile-15 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/08.jpg");
  height: 478px;
  grid-area: box15;
}
.project-view .proj-farcar #view-tile-16 {
  background-image: url("./Resources/proj-farcar/ekrany_jpg/09.jpg");
  height: 478px;
  grid-area: box16;
}
.project-view .proj-farcar #view-tile-17 {
  background-image: url("./Resources/proj-farcar/321_go.jpg");
  height: 655px;
  grid-area: box17;
}
.project-view .proj-farcar .tile-visible {
  opacity: 1;
}
.project-view .project-view-other {
  width: 100%;
}
.project-view .project-view-other h3 {
  margin: 2em 0;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
}
.project-view .project-view-other .project-view-other-container {
  position: relative;
  height: 478px;
}
.project-view .project-view-other .project-view-other-container .arrow-left,
.project-view .project-view-other .project-view-other-container .arrow-right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.project-view .project-view-other .project-view-other-container .arrow-left {
  left: 0;
}
.project-view .project-view-other .project-view-other-container .arrow-right {
  right: 0;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box {
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100%;
  -ms-overflow-style: none;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles {
  height: 100%;
  width: -moz-max-content;
  width: max-content;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .project-view-other-tile {
  width: 398px;
  background-color: white;
  height: 100%;
  display: inline-block;
  margin: 0px 8px;
  cursor: pointer;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .project-view-other-tile:first-child {
  margin-left: 0;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .project-view-other-tile:last-child {
  margin-right: 0;
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .chickens-project {
  background-image: url("./Resources/proj-chicken/cc_kafel.jpg");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .drawings-gallery {
  background-image: url("./Resources/proj-drawings/kafel_rysunki.gif");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .animations {
  background-image: url("./Resources/proj-animation/kafel_animacje.gif");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .proj-farba {
  background-image: url("./Resources/proj-farba/plakat_energiczna.jpg");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .proj-zawolcze {
  background-image: url("./Resources/proj-zawolcze/kafel_zawolcze.png");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .proj-kaseta {
  background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .proj-magazyn {
  background-image: url("./Resources/proj-magazyn/398x478_mw_tytulowy.jpg");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box .project-view-other-tiles .proj-farcar {
  background-image: url("./Resources/proj-farcar/398x478.jpg");
}
.project-view .project-view-other .project-view-other-container .project-view-other-scroll-box::-webkit-scrollbar {
  display: none;
}
.project-view .project-view-other .project-view-other-container:hover .arrow-left,
.project-view .project-view-other .project-view-other-container:hover .arrow-right {
  width: 80px;
}

footer {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  transition: all 0.3s;
}
footer .footer-container {
  width: 100%;
  padding: 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  transition-delay: 0.2s;
  overflow: hidden;
}
footer .footer-container-box {
  width: 250px;
  text-align: center;
}
footer .footer-container .footer-links a {
  text-decoration: none;
  color: #fff;
}
footer .footer-container .footer-links a:last-child {
  margin-left: 40px;
}
footer .footer-container button {
  font-weight: 700;
  font-size: 16px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

header .shrinked {
  width: 1500px;
}

@media (max-width: 1300px) {
  body .App {
    width: 100%;
  }
  header .header-container .header-box {
    padding: 16px;
  }
  #home-page {
    width: 100%;
  }
  #home-page .scroll-btn-box {
    width: 812px;
  }
  #home-page #hero {
    height: 220px;
  }
  #home-page #hero div:nth-child(1),
  #home-page #hero div:nth-child(3) {
    display: none;
  }
  #home-page #hero div:nth-child(2) {
    display: block;
  }
  #home-page #home-page-container #portfolio-container {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box2" "box1 box2" "box5 box5" "box5 box5" "box4 box4" "box4 box4" "box7 box3" "box7 box3" "box7 box8" "box7 box8" "box6 box6" "box6 box6" "box9 box10" "box9 box10" "box11 box11" "box11 box11" "box12 box12" "box13 box13" "box13 box13";
  }
  #home-page #home-page-container #portfolio-container .project-box {
    height: 478px;
  }
  #home-page #home-page-container #portfolio-container .project-box .project-box-content-mask {
    background-color: rgba(0, 0, 0, 0);
  }
  #home-page #home-page-container #portfolio-container .project-box .project-box-content-mask div {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 16px;
  }
  #home-page #home-page-container #portfolio-container .project-box {
    height: 478px;
  }
  #home-page #home-page-container #portfolio-container .project-box .project-box-content-mask {
    opacity: 1;
  }
  #home-page #home-page-container #portfolio-container .project-box:hover .project-box-content {
    transform: scale(1);
  }
  #home-page #home-page-container #portfolio-container #box-5 .project-box-content {
    background-image: url("./Resources/proj-zawolcze/kafel_szerszy_mobile.jpg");
  }
  #home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask h3 {
    margin-left: 75px;
    margin-bottom: 55px;
  }
  #home-page #home-page-container #portfolio-container #box-7 {
    height: 988px;
  }
  #home-page #home-page-container #portfolio-container #box-11 .project-box-content {
    background-image: url("./Resources/proj-farba/sniezka_kafel_mobile.jpg");
  }
  #home-page #home-page-container #portfolio-container #box-13 h2 {
    font-size: 45px;
  }
  .project-view {
    width: 812px;
  }
  .project-view .proj-kaseta {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box3" "box4 box5" "box6 box9" "box7 box7" "box8 box8" "box10 box11" "box12 box12";
  }
  .project-view .proj-kaseta #view-tile-1 {
    background-image: url("./Resources/proj-kaseta/kaseta_headline_mobile.jpg");
    height: 478px;
  }
  .project-view .proj-kaseta #view-tile-3 {
    height: 433px;
  }
  .project-view .proj-kaseta #view-tile-7 {
    background-image: url("./Resources/proj-kaseta/kaseta_02_812x478 (1).jpg");
    height: 478px;
  }
  .project-view .proj-kaseta #view-tile-12 {
    background-image: url("./Resources/proj-kaseta/kierunkowskaz_812x478.jpg");
    height: 478px;
  }
  .project-view .chickens-project {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box3" "box4 box4" "box5 box6" "box7 box7" "box8 box8" "box9 box9" "box10 box10";
  }
  .project-view .chickens-project #view-tile-1 {
    background-image: url("./Resources/proj-chicken/crazy_chickens_head_mobile.jpg");
    height: 478px;
  }
  .project-view .chickens-project #view-tile-3,
  .project-view .chickens-project #view-tile-4,
  .project-view .chickens-project #view-tile-8,
  .project-view .chickens-project #view-tile-9,
  .project-view .chickens-project #view-tile-10 {
    height: 434px;
  }
  .project-view .chickens-project #view-tile-7 {
    background-image: url("./Resources/proj-chicken/yellow-chicken_812x478.jpg");
    height: 478px;
  }
  .project-view .proj-zawolcze {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box3" "box4 box5" "box7 box7" "box6 box8" "box9 box9" "box11 box11" "box10 box13" "box12 box12" "box14 box15" "box16 box16";
  }
  .project-view .proj-zawolcze #view-tile-1 {
    height: 478px;
  }
  .project-view .proj-zawolcze #view-tile-3 {
    background-image: url("./Resources/proj-zawolcze/stawy_logotype_mobile.jpg");
    height: 478px;
  }
  .project-view .proj-zawolcze #view-tile-9 {
    background-image: url("./Resources/proj-zawolcze/stawy_produkty_mobile.jpg");
    height: 478px;
  }
  .project-view .proj-zawolcze #view-tile-15 {
    background-image: url("./Resources/proj-zawolcze/398x478_komp.jpg");
    height: 478px;
  }
  .project-view .proj-zawolcze #view-tile-16 {
    height: 478px;
  }
  .project-view .animations {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box3" "box4 box4" "box5 box5" "box6 box6" "box7 box7";
  }
  .project-view .animations #view-tile-1,
  .project-view .animations #view-tile-3,
  .project-view .animations #view-tile-4 {
    height: 435px;
  }
  .project-view .animations #view-tile-5,
  .project-view .animations #view-tile-6,
  .project-view .animations #view-tile-7 {
    height: 460px;
  }
  .project-view .drawings-gallery {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box4" "box6 box6" "box5 box7" "box8 box9" "box10 box11" "box12 box13" "box14 box15" "box16 box17" "box18 box20" "box19 box19";
  }
  .project-view .drawings-gallery #view-tile-1 {
    height: 317px;
  }
  .project-view .drawings-gallery #view-tile-6 {
    height: 316px;
  }
  .project-view .proj-magazyn {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box3" "box4 box4" "box5 box6" "box8 box8" "box7 box10" "box9 box9" "box11 box11";
  }
  .project-view .proj-magazyn #view-tile-1 {
    height: 478px;
    background-image: url("./Resources/proj-magazyn/mw_812_mobile.jpg");
  }
  .project-view .proj-magazyn #view-tile-3 {
    height: 364px;
  }
  .project-view .proj-magazyn #view-tile-4 {
    background-image: url("./Resources/proj-magazyn/mw_02_812_mobile.jpg");
    height: 478px;
  }
  .project-view .proj-magazyn #view-tile-11 {
    background-image: url("./Resources/proj-magazyn/mw_04_812_mobile.jpg");
    height: 478px;
  }
  .project-view .proj-farcar {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box4" "box5 box7" "box6 box6" "box8 box9" "box10 box11" "box12 box13" "box14 box15" "box16 box17";
  }
  .project-view .proj-farcar #view-tile-1 {
    height: 478px;
  }
  .project-view .proj-farcar #view-tile-17 {
    background-image: url("./Resources/proj-farcar/321_go_398x478.jpg");
    height: 478px;
  }
  .project-view .proj-farba {
    grid-template-columns: repeat(2, 398px);
    grid-template-areas: "box1 box1" "box2 box2" "box3 box4" "box6 box6" "box5 box7" "box8 box9" "box10 box10" "box11 box11" "box12 box12" "box13 box13" "box14 box14";
  }
  .project-view .proj-farba #view-tile-1 {
    height: 362px;
  }
  .project-view .proj-farba #view-tile-6,
  .project-view .proj-farba #view-tile-10 {
    height: 433px;
  }
  .project-view .proj-farba #view-tile-12 {
    background-image: url("./Resources/proj-farba/sniezka_812x478.jpg");
    height: 478px;
  }
}
@media (max-width: 830px) {
  header {
    top: 0;
  }
  header .mailbox {
    display: none;
  }
  header .header-box .visible {
    width: 260px;
  }
  header .header-container .header-box #menu-btn {
    display: none;
  }
  header .header-container .header-box #menu-btn-mobile {
    height: 20px;
    width: 30px;
    display: block;
  }
  header .header-container .header-box #menu-btn-mobile #hamburger {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
  header .header-container .header-box #menu-btn-mobile #hamburger .top-bread,
  header .header-container .header-box #menu-btn-mobile #hamburger .meat,
  header .header-container .header-box #menu-btn-mobile #hamburger .bottom-bread {
    transition: all 0.3s;
  }
  header .header-container .header-box #menu-btn-mobile .white .top-bread,
  header .header-container .header-box #menu-btn-mobile .white .meat,
  header .header-container .header-box #menu-btn-mobile .white .bottom-bread {
    width: 100%;
    height: 2px;
    background-color: white;
  }
  header .header-container .header-box #menu-btn-mobile .black .top-bread,
  header .header-container .header-box #menu-btn-mobile .black .bottom-bread {
    width: 100%;
    height: 2px;
    background-color: black;
  }
  header .header-container .header-box #menu-btn-mobile .black .top-bread {
    transform: translate(0, 9px) rotate(-45deg);
  }
  header .header-container .header-box #menu-btn-mobile .black .bottom-bread {
    transform: translate(0, -9px) rotate(45deg);
  }
  header .header-container .header-box .black-text #hamburger .top-bread,
  header .header-container .header-box .black-text #hamburger .meat,
  header .header-container .header-box .black-text #hamburger .bottom-bread {
    background-color: black;
  }
  header .header-container .header-background #menu-btn-mobile #hamburger .top-bread,
  header .header-container .header-background #menu-btn-mobile #hamburger .meat,
  header .header-container .header-background #menu-btn-mobile #hamburger .bottom-bread {
    background-color: black;
  }
  #home-page #hero {
    height: 70vh;
    margin-top: 30px;
  }
  #home-page #hero div:nth-child(1),
  #home-page #hero div:nth-child(2) {
    display: none;
  }
  #home-page #hero div:nth-child(3) {
    display: block;
  }
  #home-page .scroll-btn-box {
    width: 375px;
  }
  #home-page #home-page-container {
    width: 375px;
  }
  #home-page #home-page-container h1 {
    font-size: 30px;
    width: 100%;
  }
  #home-page #home-page-container #portfolio-container {
    grid-template-columns: repeat(1, 375px);
    grid-template-areas: "box1" "box1" "box2" "box2" "box6" "box6" "box4" "box4" "box5" "box5" "box3" "box3" "box7" "box7" "box8" "box8" "box9" "box9" "box10" "box10" "box11" "box11" "box12" "box13" "box13";
  }
  #home-page #home-page-container #portfolio-container #box-4 {
    background-image: url("./Resources/proj-animation/kafel_animacje_mobile.gif");
  }
  #home-page #home-page-container #portfolio-container #box-6 .project-box-content {
    background-image: url("./Resources/proj-drawings/kafel_rysunki.jpg");
  }
  #home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask {
    background-color: rgba(0, 0, 0, 0);
  }
  #home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask h3 {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  #home-page #home-page-container #portfolio-container #box-6 .project-box-content-mask h3 span {
    visibility: visible;
  }
  #home-page #home-page-container #portfolio-container #box-7 {
    height: auto;
  }
  #home-page #home-page-container #portfolio-container #box-7 p span:nth-child(1) {
    font-size: 40px;
    letter-spacing: 3px;
  }
  #home-page #home-page-container #portfolio-container #box-7 p span:nth-child(2) {
    font-weight: 700;
    font-size: 25px;
  }
  #home-page #home-page-container #portfolio-container #box-7 p {
    font-size: 25px;
  }
  #home-page #home-page-container #portfolio-container #box-11 .project-box-content {
    background-image: url("./Resources/proj-farba/plakat_energiczna.jpg");
  }
  #home-page #home-page-container #portfolio-container #box-12 div p {
    font-size: 20px;
  }
  #home-page #home-page-container #portfolio-container #box-12 div span {
    font-size: 18px;
  }
  #home-page #home-page-container #portfolio-container #box-13 .bolded-row {
    font-weight: 700;
    font-size: 16px;
  }
  #home-page #home-page-container #portfolio-container #box-13 .detail-row {
    font-size: 11px;
    font-weight: 300;
  }
  #home-page #home-page-container #portfolio-container #box-13 .detail-row p {
    padding-left: 5px;
  }
  #home-page #home-page-container #portfolio-container .exp {
    opacity: 0;
  }
  #home-page #home-page-container #portfolio-container .exp-animated {
    opacity: 1;
  }
  #home-page #home-page-container #portfolio-container .exp-animated .line {
    animation: exp-animation-slider;
    animation-duration: 1.2s;
    animation-iteration-count: 1;
  }
  #home-page #home-page-container #portfolio-container .exp-animated .circle,
  #home-page #home-page-container #portfolio-container .exp-animated .circle-2,
  #home-page #home-page-container #portfolio-container .exp-animated .circle-3 {
    animation: exp-animation-circle;
    animation-duration: 750ms;
    animation-iteration-count: 1;
  }
  #home-page #home-page-container #portfolio-container .exp-animated .detail-row,
  #home-page #home-page-container #portfolio-container .exp-animated .bolded-row {
    animation: exp-animation-text;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
  }
  .project-view {
    width: 375px;
  }
  .project-view h2 {
    font-size: 40px;
  }
  .project-view .project-view-other .project-view-other-container .arrow-left,
  .project-view .project-view-other .project-view-other-container .arrow-right {
    height: 60px;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translate(0, 418px);
  }
  .project-view .project-view-other .project-view-other-container:hover .arrow-left,
  .project-view .project-view-other .project-view-other-container:hover .arrow-right {
    width: 50%;
  }
  .project-view .proj-kaseta {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .proj-kaseta #view-tile-1 {
    height: 245px;
  }
  .project-view .proj-kaseta #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .proj-kaseta #view-tile-3 {
    height: 222px;
  }
  .project-view .proj-kaseta #view-tile-7 {
    height: 245px;
  }
  .project-view .proj-kaseta #view-tile-4,
  .project-view .proj-kaseta #view-tile-5,
  .project-view .proj-kaseta #view-tile-6,
  .project-view .proj-kaseta #view-tile-8,
  .project-view .proj-kaseta #view-tile-9,
  .project-view .proj-kaseta #view-tile-10,
  .project-view .proj-kaseta #view-tile-11,
  .project-view .proj-kaseta #view-tile-12 {
    height: 241px;
  }
  .project-view .chickens-project {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .chickens-project #view-tile-1 {
    height: 245px;
  }
  .project-view .chickens-project #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .chickens-project #view-tile-3,
  .project-view .chickens-project #view-tile-4,
  .project-view .chickens-project #view-tile-8,
  .project-view .chickens-project #view-tile-9,
  .project-view .chickens-project #view-tile-10 {
    height: 222px;
  }
  .project-view .chickens-project #view-tile-5,
  .project-view .chickens-project #view-tile-6,
  .project-view .chickens-project #view-tile-7 {
    height: 241px;
  }
  .project-view .drawings-gallery {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .drawings-gallery #view-tile-1 {
    background-position: left;
    width: 375px;
    height: 288px;
  }
  .project-view .drawings-gallery #view-tile-3,
  .project-view .drawings-gallery #view-tile-4,
  .project-view .drawings-gallery #view-tile-5,
  .project-view .drawings-gallery #view-tile-7,
  .project-view .drawings-gallery #view-tile-8,
  .project-view .drawings-gallery #view-tile-9,
  .project-view .drawings-gallery #view-tile-10,
  .project-view .drawings-gallery #view-tile-11,
  .project-view .drawings-gallery #view-tile-12,
  .project-view .drawings-gallery #view-tile-13,
  .project-view .drawings-gallery #view-tile-14,
  .project-view .drawings-gallery #view-tile-15,
  .project-view .drawings-gallery #view-tile-16,
  .project-view .drawings-gallery #view-tile-17,
  .project-view .drawings-gallery #view-tile-18,
  .project-view .drawings-gallery #view-tile-19,
  .project-view .drawings-gallery #view-tile-20 {
    height: 285px;
  }
  .project-view .drawings-gallery #view-tile-6 {
    height: 160px;
  }
  .project-view .animations {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .animations #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .animations #view-tile-1,
  .project-view .animations #view-tile-3,
  .project-view .animations #view-tile-4 {
    height: 200px;
  }
  .project-view .animations #view-tile-5,
  .project-view .animations #view-tile-6,
  .project-view .animations #view-tile-7 {
    height: 210px;
  }
  .project-view .proj-zawolcze {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .proj-zawolcze #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .proj-zawolcze #view-tile-1,
  .project-view .proj-zawolcze #view-tile-3,
  .project-view .proj-zawolcze #view-tile-4,
  .project-view .proj-zawolcze #view-tile-5,
  .project-view .proj-zawolcze #view-tile-6,
  .project-view .proj-zawolcze #view-tile-7,
  .project-view .proj-zawolcze #view-tile-8,
  .project-view .proj-zawolcze #view-tile-9,
  .project-view .proj-zawolcze #view-tile-10,
  .project-view .proj-zawolcze #view-tile-11,
  .project-view .proj-zawolcze #view-tile-12,
  .project-view .proj-zawolcze #view-tile-13,
  .project-view .proj-zawolcze #view-tile-14,
  .project-view .proj-zawolcze #view-tile-15,
  .project-view .proj-zawolcze #view-tile-16 {
    height: 210px;
  }
  .project-view .proj-magazyn {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .proj-magazyn #view-tile-1,
  .project-view .proj-magazyn #view-tile-4,
  .project-view .proj-magazyn #view-tile-5,
  .project-view .proj-magazyn #view-tile-6,
  .project-view .proj-magazyn #view-tile-7,
  .project-view .proj-magazyn #view-tile-9,
  .project-view .proj-magazyn #view-tile-10,
  .project-view .proj-magazyn #view-tile-11 {
    height: 210px;
  }
  .project-view .proj-magazyn #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .proj-magazyn #view-tile-3 {
    height: 167px;
  }
  .project-view .proj-magazyn #view-tile-8 {
    height: 200px;
  }
  .project-view .proj-farcar {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .proj-farcar #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .proj-farcar #view-tile-1,
  .project-view .proj-farcar #view-tile-3,
  .project-view .proj-farcar #view-tile-4,
  .project-view .proj-farcar #view-tile-5,
  .project-view .proj-farcar #view-tile-6,
  .project-view .proj-farcar #view-tile-7,
  .project-view .proj-farcar #view-tile-8,
  .project-view .proj-farcar #view-tile-9,
  .project-view .proj-farcar #view-tile-10,
  .project-view .proj-farcar #view-tile-11,
  .project-view .proj-farcar #view-tile-12,
  .project-view .proj-farcar #view-tile-13,
  .project-view .proj-farcar #view-tile-14,
  .project-view .proj-farcar #view-tile-15,
  .project-view .proj-farcar #view-tile-16,
  .project-view .proj-farcar #view-tile-17 {
    height: 210px;
  }
  .project-view .proj-farcar #view-tile-4 {
    overflow: hidden;
  }
  .project-view .proj-farcar #view-tile-4 video {
    height: 215px;
  }
  .project-view .proj-farba {
    grid-template-columns: repeat(2, 179px);
  }
  .project-view .proj-farba #view-tile-1 {
    height: 167px;
  }
  .project-view .proj-farba #view-tile-2 {
    font-size: 20px;
    padding: 10px;
  }
  .project-view .proj-farba #view-tile-3,
  .project-view .proj-farba #view-tile-4,
  .project-view .proj-farba #view-tile-5,
  .project-view .proj-farba #view-tile-7,
  .project-view .proj-farba #view-tile-8,
  .project-view .proj-farba #view-tile-9,
  .project-view .proj-farba #view-tile-11,
  .project-view .proj-farba #view-tile-12 {
    height: 215px;
  }
  .project-view .proj-farba #view-tile-6,
  .project-view .proj-farba #view-tile-10 {
    height: 200px;
  }
  footer {
    padding: 15px;
  }
  footer .footer-container .footer-links {
    display: flex;
    flex-direction: column;
    width: 150px;
  }
  footer .footer-container .footer-links a:last-child {
    margin: 0;
  }
  footer .footer-container .footer-links a {
    text-align: left;
  }
  footer button {
    width: 100px;
  }
  footer .footer-info {
    width: 150px;
  }
}/*# sourceMappingURL=index.css.map */