@import "../Variables/variables";

.proj-farcar {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box4 box5"
    "box6 box6 box7"
    "box8 box9 box10"
    "box11 box12 box13"
    "box14 box15 box16"
    "box17 box17 box17";

  .project-view-tile {
    opacity: 0;
    transition: all 0.5s;
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-farcar/headline.jpg");
    height: 655px;
    grid-area: box1;
  }

  #view-tile-2 {
    min-height: 300px;
    grid-area: box2;
    background-color: transparent;
    font-size: $font-size-medium-subpages;

    span {
      font-weight: $font-weight-bold;
    }
  }
  #view-tile-3 {
    background-image: url("./Resources/proj-farcar/fc_01.jpg");
    height: 478px;
    grid-area: box3;
  }
  #view-tile-4 {
    height: 478px;
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-farcar/fc_02.jpg");
    height: 478px;
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-farcar/812x478_fc.jpg");
    height: 478px;
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-farcar/398x478_02.jpg");
    height: 478px;
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/app_frames.jpg");
    height: 478px;
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/02.jpg");
    height: 478px;
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/03.jpg");
    height: 478px;
    grid-area: box10;
  }
  #view-tile-11 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/04.jpg");
    height: 478px;
    grid-area: box11;
  }
  #view-tile-12 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/05.jpg");
    height: 478px;
    grid-area: box12;
  }
  #view-tile-13 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/06.jpg");
    height: 478px;
    grid-area: box13;
  }
  #view-tile-14 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/07.jpg");
    height: 478px;
    grid-area: box14;
  }
  #view-tile-15 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/08.jpg");
    height: 478px;
    grid-area: box15;
  }
  #view-tile-16 {
    background-image: url("./Resources/proj-farcar/ekrany_jpg/09.jpg");
    height: 478px;
    grid-area: box16;
  }
  #view-tile-17 {
    background-image: url("./Resources/proj-farcar/321_go.jpg");
    height: 655px;
    grid-area: box17;
  }

  .tile-visible {
    opacity: 1;
  }
}
