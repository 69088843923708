@import "../Variables/variables";

.proj-kaseta {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box3 box3"
    "box4 box5 box6"
    "box7 box7 box7"
    "box8 box8 box9"
    "box10 box11 box12";

  .project-view-tile {
    opacity: 0;
    transition: all 0.5s;
    .animation {
      width: 100%;
      height: 100%;
    }

    a {
      color: #fff;
      font-size: $font-size-regular;
    }
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-kaseta/kaseta_headline.jpg");
    height: 655px;
    grid-area: box1;
  }

  #view-tile-2 {
    min-height: 200px;
    grid-area: box2;
    background-color: transparent;
    font-size: $font-size-medium-subpages;

    span {
      font-weight: $font-weight-bold;
    }
  }
  #view-tile-3 {
    height: 655px;
    grid-area: box3;
  }
  #view-tile-4 {
    background-image: url("./Resources/proj-kaseta/398x478.jpg");
    height: 478px;
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-kaseta/398x478_bluzal.jpg");
    height: 478px;
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
    height: 478px;
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-kaseta/1226x655.jpg");
    height: 655px;
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-kaseta/812x478_fale.jpg");
    height: 478px;
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-kaseta/398x478_girl.jpg");
    height: 478px;
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-kaseta/348x478education.jpg");
    height: 478px;
    grid-area: box10;
  }
  #view-tile-11 {
    background-image: url("./Resources/proj-kaseta/348x478art.jpg");
    height: 478px;
    grid-area: box11;
  }
  #view-tile-12 {
    background-image: url("./Resources/proj-kaseta/348x478_kierunkowskaz.jpg");
    height: 478px;
    grid-area: box12;
  }

  .tile-visible {
    opacity: 1;
  }
}
