@import "../Variables/variables";

.proj-farba {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box4 box5"
    "box6 box6 box6"
    "box7 box8 box9"
    "box10 box10 box10"
    "box11 box11 box12"
    "box13 box13 box13"
    "box14 box14 box14";

  .project-view-tile {
    opacity: 0;
    transition: all 0.5s;
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-farba/1226x687_headline_plamoodporna.jpg");
    height: 547px;
    grid-area: box1;
  }
  #view-tile-2 {
    min-height: 300px;
    grid-area: box2;
    font-size: $font-size-medium-subpages;
    div p:first-child,
    span {
      font-weight: $font-weight-bold;
    }
  }
  #view-tile-3 {
    background-image: url("./Resources/proj-farba/398x478_energ.jpg");
    height: 478px;
    grid-area: box3;
  }
  #view-tile-4 {
    background-image: url("./Resources/proj-farba/398x478_kreatywna.jpg");
    height: 478px;
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-farba/398x478_odkrywcza.jpg");
    height: 478px;
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-farba/1226x655_plamoodp_site.jpg");
    height: 655px;
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-farba/398x478_subtelna.jpg");
    height: 478px;
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-farba/398x478_taktowna.jpg");
    height: 478px;
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-farba/398x478_zjawiskowa.jpg");
    height: 478px;
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-farba/1226x655_plamoodp_busstop02.jpg");
    height: 655px;
    grid-area: box10;
  }
  #view-tile-11 {
    background-image: url("./Resources/proj-farba/812x478_plamoodp_gazeta.jpg");
    height: 478px;
    grid-area: box11;
  }
  #view-tile-12 {
    background-image: url("./Resources/proj-farba/398x478_plamoodp03.jpg");
    height: 478px;
    grid-area: box12;
  }
  #view-tile-13 {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: box13;
    video {
      width: 100%;
    }
  }
  #view-tile-14 {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: box14;
    video {
      width: 100%;
    }
  }

  .tile-visible {
    opacity: 1;
  }
}
