@import "../Variables/variables";

@keyframes exp-animation-slider {
  0% {
    width: 0;
  }
  33% {
    width: 0;
  }
  66% {
    width: 0;
  }

  100% {
    width: 98%;
  }
}

@keyframes exp-animation-circle {
  0% {
    top: 37%;
    width: 0;
    height: 0;
  }
  50% {
    top: 37%;
    width: 0;
    height: 0;
  }
  100% {
    top: 25%;
    width: 25px;
    height: 25px;
  }
}

@keyframes exp-animation-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scroll-arrow-bounce {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(0, 10px);
  }
  10% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, 10px);
  }
  20% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  55% {
    transform: translate(0, 10px);
  }
  60% {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, 10px);
  }
  70% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

#home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #hero {
    margin-top: 250px;
    // margin-bottom: 100px;
    height: 400px;
    width: 100%;
    div {
      height: 100%;
      width: 100%;
    }
    div:nth-child(2),
    div:nth-child(3) {
      display: none;
    }
  }

  .scroll-btn-box {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
    transition: all 0.3s;
    button {
      animation: scroll-arrow-bounce 5s infinite;
      height: 50px;
      width: 50px;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      svg {
        height: 100%;
        width: 100%;
        color: #fff;
      }
    }
  }

  .hide {
    visibility: hidden;
  }

  #home-page-container {
    h1 {
      font-size: $font-size-medium;
      font-weight: $font-weight-light;
      text-transform: uppercase;
      margin-bottom: 48px;
    }

    #portfolio-container {
      display: grid;
      grid-template-columns: repeat(3, 398px);
      grid-auto-rows: minmax(auto, auto);
      column-gap: 16px;
      row-gap: 48px;
      grid-template-areas:
        "box1 box2 box3"
        "box1 box2 box3"
        "box4 box4 box5"
        "box4 box4 box5"
        "box7 box7 box7"
        "box6 box6 box6"
        "box6 box6 box6"
        "box8 box9 box10"
        "box8 box9 box10"
        "box11 box11 box11"
        "box11 box11 box11"
        "box12 box12 box12"
        "box13 box13 box13";

      .project-box {
        background-color: $background-tile-color;
        position: relative;
        overflow: hidden;
        transition: all 0.5s;

        .project-box-content {
          cursor: pointer;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 100%;
          transition: all 0.3s;
        }

        .project-box-content-mask {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
          transition: all 0.3s;
          opacity: 0;
          background-color: $background-tile-color;
          display: flex;
          align-items: flex-end;
          padding: $small-padding;
          h3 {
            width: 100%;
            font-size: $font-size-medium;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
          }
          p {
            font-size: $font-size-regular;
            font-weight: $font-weight-light;
            text-transform: none;
          }
        }
      }

      .project-box:hover {
        .project-box-content {
          transform: scale(1.2);
        }

        .project-box-content-mask {
          opacity: 1;
        }
      }

      .project-tile {
        height: (207px * 2) +64px;
      }

      .text {
        background-color: transparent;
      }

      #box-1 {
        grid-area: box1;
        .project-box-content {
          background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
        }
      }
      #box-2 {
        grid-area: box2;
        .project-box-content {
          background-image: url("./Resources/proj-chicken/cc_kafel.jpg");
        }
      }
      #box-3 {
        grid-area: box3;
        background-image: url("./Resources/kafel_test.jpg");
      }
      #box-4 {
        grid-area: box4;
        background-image: url("./Resources/proj-animation/animowany_bear_kafel.gif");
      }
      #box-5 {
        grid-area: box5;
        .project-box-content {
          background-image: url("./Resources/proj-zawolcze/kafel_zawolcze.png");
        }
      }
      #box-6 {
        grid-area: box6;
        .project-box-content {
          background-image: url("./Resources/proj-drawings/drawings_main02.jpg");
        }
        .project-box-content-mask {
          h3 {
            margin-left: 238px;
            margin-bottom: 32px;
            span {
              visibility: hidden;
            }
          }
        }
      }
      #box-7 {
        grid-area: box7;
        padding: 0;
        p {
          span:nth-child(1) {
            text-transform: uppercase;
            font-size: $font-size-big;
            font-weight: $font-weight-light;
            letter-spacing: 3px;
          }
          span:nth-child(2) {
            font-weight: $font-weight-bold;
            font-size: $font-size-medium;
          }
        }

        p {
          font-size: $font-size-medium;
          font-weight: $font-weight-bold;
        }
      }
      #box-8 {
        grid-area: box8;
        background-image: url("./Resources/kafel_idea.jpg");
      }
      #box-9 {
        grid-area: box9;
        .project-box-content {
          background-image: url("./Resources/proj-magazyn/398x478_mw_tytulowy.jpg");
        }
      }
      #box-10 {
        grid-area: box10;
        .project-box-content {
          background-image: url("./Resources/proj-farcar/398x478.jpg");
        }
      }
      #box-11 {
        grid-area: box11;
        .project-box-content {
          background-image: url("./Resources/proj-farba/kafel_plamoodporna.jpg");
        }
      }

      #box-12 {
        grid-row: 100px;
        grid-area: box12;
        height: 80px;
        border: 2px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        cursor: pointer;

        div {
          p {
            font-size: $font-size-medium;
            display: inline-block;
          }
          span {
            display: inline-block;
            font-size: ($font-size-medium - 5px);
            margin-left: 20px;
          }
        }
      }

      #box-12:hover {
        background-color: #fff;
        div {
          p,
          span {
            color: #000;
          }
        }
      }

      #box-13 {
        grid-area: box13;
        padding: 0;

        h2 {
          font-size: $font-size-big;
          font-weight: $font-weight-light;
          text-transform: uppercase;
          margin-bottom: 50px;
        }

        div {
          display: flex;
          p {
            width: 50%;
          }
        }
        .slider {
          position: relative;
          height: 60px;
          width: 100%;

          .circle,
          .circle-2,
          .circle-3 {
            position: absolute;
            left: 0%;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: $font-main-color;
            margin: 0;
            top: 25%;
          }

          .circle-2 {
            left: 33%;
          }

          .circle-3 {
            left: 66%;
          }

          .line {
            top: 45%;
            width: 98%;
            position: absolute;
            background-color: $font-main-color;
            height: 2px;
            margin: 0;
          }
        }
        .bolded-row {
          font-weight: $font-weight-bold;
          font-size: $font-size-medium;
        }
        .detail-row {
          font-size: $font-size-regular;
          font-weight: $font-weight-light;
          p {
            padding-left: 22px;
          }
        }
      }

      .exp {
        opacity: 0;
      }

      .exp-animated {
        opacity: 1;

        .line {
          animation: exp-animation-slider;
          animation-duration: 1.2s;
          animation-iteration-count: 1;
        }

        .circle,
        .circle-2,
        .circle-3 {
          animation: exp-animation-circle;
          animation-duration: 750ms;
          animation-iteration-count: 1;
        }

        .detail-row,
        .bolded-row {
          animation: exp-animation-text;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
        }
      }
    }
  }
}
