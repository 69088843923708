@import "../Variables/variables";

.project-view {
  width: 1226px;
  padding-top: $medium-padding;
  h2 {
    text-transform: uppercase;
    font-size: $font-size-big;
    font-weight: $font-weight-light;
  }

  .project-view-container {
    display: grid;
    column-gap: 16px;
    row-gap: 48px;

    .project-view-tile {
      background-position: center;
      background-size: cover;
    }
    .text {
      background-color: transparent;
      display: flex;

      h3 {
        width: 400px;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-size: $font-size-medium;
        line-height: $font-size-medium;
      }
    }
  }

  @import "./kaseta";
  @import "./chicken";
  @import "./zawolcze";
  @import "./drawings";
  @import "./animations";
  @import "./magazyn";
  @import "./farba";
  @import "./farcar";

  @import "../Components/otherProjects";
}
