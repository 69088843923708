@import "../Variables/variables";

.chickens-project {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box3 box3"
    "box4 box4 box4"
    "box5 box6 box7"
    "box8 box8 box8"
    "box9 box9 box9"
    "box10 box10 box10";

  .project-view-tile {
    opacity: 0;
    transition: all 0.5s;
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-chicken/1cc_headline_tytułowy.jpg");
    height: 547px;
    grid-area: box1;
  }
  #view-tile-2 {
    min-height: 200px;
    grid-area: box2;

    font-size: $font-size-medium-subpages;
    div p:first-child,
    span {
      font-weight: $font-weight-bold;
    }
  }
  #view-tile-3 {
    background-image: url("./Resources/proj-chicken/2cc_wide.jpg");
    height: 655px;
    grid-area: box3;
  }
  #view-tile-4 {
    background-image: url("./Resources/proj-chicken/3cc_koszulki_1226x655.jpg");
    height: 655px;
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-chicken/4cc_black.jpg");
    height: 478px;
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-chicken/5kafel_boxy.jpg");
    height: 478px;
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-chicken/6cc_yellow.jpg");
    height: 478px;
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-chicken/7-1226x655_truck.jpg");
    height: 655px;
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-chicken/8cc_fartuch_1226x655.jpg");
    height: 655px;
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-chicken/9cc_wlepka_1226x655.jpg");
    height: 655px;
    grid-area: box10;
  }

  .tile-visible {
    opacity: 1;
  }
}
