@import "./Variables/variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: $background-main-color;
  color: $font-main-color;
  font-family: $font-family;
  transition: all 0.5s;
  #root {
    position: relative;
    display: flex;
    justify-content: center;
    .App {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.body-black {
  background-color: black;
}

@import "./Components/header";
@import "./Pages/home";
@import "./Pages/projectView";
@import "./Components/footer";

// misc
header .shrinked {
  width: 1500px;
}

@import "./responsive/responsive";
