@import "../Variables/variables";

.drawings-gallery {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box4 box5"
    "box6 box6 box6"
    "box7 box8 box9"
    "box10 box11 box12"
    "box13 box14 box15"
    "box16 box17 box18"
    "box19 box19 box20";

  .project-view-tile {
    height: 478px;
    opacity: 0;
    transition: all 0.5s;

    .drawing-mask {
      background-color: rgba(0, 0, 0, 0.7);
      transition: all 0.3s;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .content:hover {
    cursor: pointer;

    .drawing-mask {
      opacity: 1;
    }
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-drawings/drawings_main_white.jpg");
    grid-area: box1;
  }
  #view-tile-2 {
    grid-area: box2;
    font-size: $font-size-medium-subpages;
    height: auto;
    div {
      p:first-child {
        margin-bottom: $small-margin;
      }
    }
  }
  #view-tile-3 {
    background-image: url("./Resources/proj-drawings/girl.jpg");
    grid-area: box3;
  }
  #view-tile-4 {
    background-image: url("./Resources/proj-drawings/p-blinders.jpg");
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-drawings/wolf.jpg");
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-drawings/dragon.jpg");
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-drawings/faun.jpg");
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-drawings/flowers.jpg");
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-drawings/eagle.jpg");
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-drawings/grzybek.jpg");
    grid-area: box10;
  }
  #view-tile-11 {
    background-image: url("./Resources/proj-drawings/motyl.jpg");
    grid-area: box11;
  }
  #view-tile-12 {
    background-image: url("./Resources/proj-drawings/panda.jpg");
    grid-area: box12;
  }
  #view-tile-13 {
    background-image: url("./Resources/proj-drawings/face01.jpg");
    grid-area: box13;
  }
  #view-tile-14 {
    background-image: url("./Resources/proj-drawings/root.jpg");
    grid-area: box14;
  }
  #view-tile-15 {
    background-image: url("./Resources/proj-drawings/rzezba.jpg");
    grid-area: box15;
  }
  #view-tile-16 {
    background-image: url("./Resources/proj-drawings/tiger.jpg");
    grid-area: box16;
  }
  #view-tile-17 {
    background-image: url("./Resources/proj-drawings/war.jpg");
    grid-area: box17;
  }
  #view-tile-18 {
    background-image: url("./Resources/proj-drawings/face02.jpg");
    grid-area: box18;
  }
  #view-tile-19 {
    background-image: url("./Resources/proj-drawings/rubicon_mojewww.jpg");
    grid-area: box19;
  }
  #view-tile-20 {
    background-image: url("./Resources/proj-drawings/zamek_hauru.jpg");
    grid-area: box20;
  }

  .tile-visible {
    opacity: 1;
  }
}
