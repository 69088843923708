@import "../Variables/variables";

@keyframes mailhint {
  0% {
    height: 0;
  }

  10% {
    height: 90px;
    background-color: $background-secondary-color;
    padding: 10px;
  }

  90% {
    height: 90px;
    background-color: $background-secondary-color;
    padding: 10px;
  }

  100% {
    height: 0;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 20px;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;

  .header-container {
    width: 100%;
    transition-property: width, padding, background-color;
    transition-timing-function: ease-in-out;
    transition-delay: 0.2s;
    transition-duration: 0.3s;
  }

  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: calc(#{$small-padding}* 0.5) $medium-padding;
    transition-property: color, font-weight, background-color, padding;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    position: relative;

    @import "./menu";
  }

  #logo-box {
    height: 35px;
    width: 35px;
    cursor: pointer;
  }

  .mailbox {
    position: relative;

    a {
      text-decoration: none;
      color: $font-main-color;
      font-weight: $font-weight-semibold;
      font-size: $font-size-regular;
    }

    .mailbox-info,
    .mailbox-info-2,
    .mailbox-info-3 {
      position: absolute;
      top: 100%;
      text-align: center;
      font-size: $font-size-small;
      // background-color: $background-secondary-color;
      color: #000;
      font-weight: $font-weight-bold;
      overflow: hidden;
      height: 0;
      transition: all 0.3s;
      width: 90px;
      // padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mailbox-info {
      border-radius: 50%;
      left: 100%;
    }

    .mailbox-info-2 {
      border-radius: 50%;
      right: 100%;
    }

    .mailbox-info-3 {
      top: 40px;
      transform: translateX(-50%);
      border-radius: 10px;
      left: 50%;
    }

    .visible-info {
      height: 20px;
      padding: 10px;
      background-color: $background-secondary-color;
    }
  }

  .mailbox:hover {
    .mailbox-info {
      animation: mailhint;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
    .mailbox-info-2 {
      animation: mailhint;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
      animation-delay: 1s;
    }
  }

  .header-background {
    background-color: $header-background-color;
    a,
    button {
      color: $font-secondary-color;
      font-weight: $font-weight-bold;
    }
  }
}
