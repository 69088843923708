@import "../Variables/variables";

footer {
  font-size: $font-size-regular;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: $font-weight-bold;
  transition: all 0.3s;

  .footer-container {
    width: 100%;
    padding: $small-padding 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;
    transition-delay: 0.2s;
    overflow: hidden;

    &-box {
      width: 250px;
      text-align: center;
    }

    .footer-links {
      a {
        text-decoration: none;
        color: $font-main-color;
      }

      a:last-child {
        margin-left: 40px;
      }
    }

    button {
      font-weight: $font-weight-bold;
      font-size: $font-size-regular;
      background: none;
      border: none;
      outline: none;
      color: $font-main-color;
      cursor: pointer;
    }
  }
}
