.project-view-other {
  width: 100%;
  h3 {
    margin: $small-padding 0;
    text-transform: uppercase;
    font-weight: $font-weight-light;
    font-size: $font-size-medium;
    line-height: $font-size-medium;
  }

  .project-view-other-container {
    position: relative;
    height: 478px;

    .arrow-left,
    .arrow-right {
      position: absolute;
      top: 0;
      height: 100%;
      width: 0px;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      cursor: pointer;
    }
    .arrow-left {
      left: 0;
    }
    .arrow-right {
      right: 0;
    }
    .project-view-other-scroll-box {
      overflow-y: hidden;
      overflow-x: scroll;
      height: 100%;
      -ms-overflow-style: none;

      .project-view-other-tiles {
        height: 100%;
        width: max-content;

        .project-view-other-tile {
          width: 398px;
          background-color: white;
          height: 100%;
          display: inline-block;
          margin: 0px 8px;
          cursor: pointer;
        }

        .project-view-other-tile:first-child {
          margin-left: 0;
        }
        .project-view-other-tile:last-child {
          margin-right: 0;
        }

        .chickens-project {
          background-image: url("./Resources/proj-chicken/cc_kafel.jpg");
        }
        .drawings-gallery {
          background-image: url("./Resources/proj-drawings/kafel_rysunki.gif");
        }
        .animations {
          background-image: url("./Resources/proj-animation/kafel_animacje.gif");
        }
        .proj-farba {
          background-image: url("./Resources/proj-farba/plakat_energiczna.jpg");
        }
        .proj-zawolcze {
          background-image: url("./Resources/proj-zawolcze/kafel_zawolcze.png");
        }
        .proj-kaseta {
          background-image: url("./Resources/proj-kaseta/398x478_kafel_kaseta.jpg");
        }
        .proj-magazyn {
          background-image: url("./Resources/proj-magazyn/398x478_mw_tytulowy.jpg");
        }
        .proj-farcar {
          background-image: url("./Resources/proj-farcar/398x478.jpg");
        }
      }
    }

    .project-view-other-scroll-box::-webkit-scrollbar {
      display: none;
    }
  }
  .project-view-other-container:hover {
    .arrow-left,
    .arrow-right {
      width: 80px;
    }
  }
}
