@import "../Variables/variables";

.proj-zawolcze {
  grid-template-columns: repeat(3, 398px);
  grid-auto-rows: auto;
  grid-template-areas:
    "box1 box1 box1"
    "box2 box2 box2"
    "box3 box3 box3"
    "box4 box5 box6"
    "box7 box7 box8"
    "box9 box9 box9"
    "box10 box11 box11"
    "box12 box12 box13"
    "box14 box15 box15"
    "box16 box16 box16";

  .project-view-tile {
    opacity: 0;
    transition: all 0.5s;
  }

  #view-tile-1 {
    background-image: url("./Resources/proj-zawolcze/1226x687_headline_ryby.jpg");
    height: 547px;
    grid-area: box1;
  }
  #view-tile-2 {
    min-height: 300px;
    grid-area: box2;
    font-size: $font-size-medium-subpages;
    div {
      span {
        font-weight: $font-weight-bold;
      }
      p:first-child {
        margin-bottom: $small-margin;
      }
    }
  }
  #view-tile-3 {
    background-image: url("./Resources/proj-zawolcze/1226x655_logo_01.jpg");
    height: 655px;
    grid-area: box3;
  }
  #view-tile-4 {
    background-image: url("./Resources/proj-zawolcze/398x478_02.jpg");
    height: 478px;
    grid-area: box4;
  }
  #view-tile-5 {
    background-image: url("./Resources/proj-zawolcze/398x47805.jpg");
    height: 478px;
    grid-area: box5;
  }
  #view-tile-6 {
    background-image: url("./Resources/proj-zawolcze/398x478_03.jpg");
    height: 478px;
    grid-area: box6;
  }
  #view-tile-7 {
    background-image: url("./Resources/proj-zawolcze/812x478_opakowania_c.jpg");
    height: 478px;
    grid-area: box7;
  }
  #view-tile-8 {
    background-image: url("./Resources/proj-zawolcze/398x47801_c.jpg");
    height: 478px;
    grid-area: box8;
  }
  #view-tile-9 {
    background-image: url("./Resources/proj-zawolcze/1226x655.jpg");
    height: 655px;
    grid-area: box9;
  }
  #view-tile-10 {
    background-image: url("./Resources/proj-zawolcze/398x478_wedzony.jpg");
    height: 478px;
    grid-area: box10;
  }
  #view-tile-11 {
    background-image: url("./Resources/proj-zawolcze/812x478_paprykarz.jpg");
    height: 478px;
    grid-area: box11;
  }
  #view-tile-12 {
    background-image: url("./Resources/proj-zawolcze/812x478_stoisko.jpg");
    height: 478px;
    grid-area: box12;
  }
  #view-tile-13 {
    background-image: url("./Resources/proj-zawolcze/398x478_stoisko.jpg");
    height: 478px;
    grid-area: box13;
  }
  #view-tile-14 {
    background-image: url("./Resources/proj-zawolcze/348x478_insta.jpg");
    height: 478px;
    grid-area: box14;
  }
  #view-tile-15 {
    background-image: url("./Resources/proj-zawolcze/812x478_komp.jpg");
    height: 478px;
    grid-area: box15;
  }
  #view-tile-16 {
    background-image: url("./Resources/proj-zawolcze/stawy.png");
    height: 655px;
    grid-area: box16;
  }

  .tile-visible {
    opacity: 1;
  }
}
