@import "../Variables/variables";

@media (max-width: 1300px) {
  body {
    .App {
      width: 100%;
    }
  }

  header {
    .header-container {
      .header-box {
        padding: 16px;
      }
    }
  }

  #home-page {
    width: 100%;

    .scroll-btn-box {
      width: 812px;
    }

    #hero {
      height: 220px;
      div:nth-child(1),
      div:nth-child(3) {
        display: none;
      }
      div:nth-child(2) {
        display: block;
      }
    }
    #home-page-container {
      #portfolio-container {
        grid-template-columns: repeat(2, 398px);
        grid-template-areas:
          "box1 box2"
          "box1 box2"
          "box5 box5"
          "box5 box5"
          "box4 box4"
          "box4 box4"
          "box7 box3"
          "box7 box3"
          "box7 box8"
          "box7 box8"
          "box6 box6"
          "box6 box6"
          "box9 box10"
          "box9 box10"
          "box11 box11"
          "box11 box11"
          "box12 box12"
          "box13 box13"
          "box13 box13";

        .project-box {
          height: 478px;
          .project-box-content-mask {
            background-color: rgba(0, 0, 0, 0);
            div {
              background-color: rgba(0, 0, 0, 0.7);
              padding: 16px;
            }
          }
        }

        .project-box {
          height: 478px;
          .project-box-content-mask {
            opacity: 1;
          }
        }
        .project-box:hover {
          .project-box-content {
            transform: scale(1);
          }
        }

        #box-5 {
          .project-box-content {
            background-image: url("./Resources/proj-zawolcze/kafel_szerszy_mobile.jpg");
          }
        }
        #box-6 {
          .project-box-content-mask {
            h3 {
              margin-left: 75px;
              margin-bottom: 55px;
            }
          }
        }

        #box-7 {
          height: 988px;
        }

        #box-11 {
          .project-box-content {
            background-image: url("./Resources/proj-farba/sniezka_kafel_mobile.jpg");
          }
        }

        #box-13 {
          h2 {
            font-size: 45px;
          }
        }
      }
    }
  }

  .project-view {
    width: 812px;

    .proj-kaseta {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box3"
        "box4 box5"
        "box6 box9"
        "box7 box7"
        "box8 box8"
        "box10 box11"
        "box12 box12";

      #view-tile-1 {
        background-image: url("./Resources/proj-kaseta/kaseta_headline_mobile.jpg");
        height: 478px;
      }

      #view-tile-3 {
        height: 433px;
      }

      #view-tile-7 {
        background-image: url("./Resources/proj-kaseta/kaseta_02_812x478 (1).jpg");
        height: 478px;
      }

      #view-tile-12 {
        background-image: url("./Resources/proj-kaseta/kierunkowskaz_812x478.jpg");
        height: 478px;
      }
    }

    .chickens-project {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box3"
        "box4 box4"
        "box5 box6"
        "box7 box7"
        "box8 box8"
        "box9 box9"
        "box10 box10";

      #view-tile-1 {
        background-image: url("./Resources/proj-chicken/crazy_chickens_head_mobile.jpg");
        height: 478px;
      }

      #view-tile-3,
      #view-tile-4,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10 {
        height: 434px;
      }
      #view-tile-7 {
        background-image: url("./Resources/proj-chicken/yellow-chicken_812x478.jpg");
        height: 478px;
      }
    }

    .proj-zawolcze {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box3"
        "box4 box5"
        "box7 box7"
        "box6 box8"
        "box9 box9"
        "box11 box11"
        "box10 box13"
        "box12 box12"
        "box14 box15"
        "box16 box16";

      #view-tile-1 {
        height: 478px;
      }

      #view-tile-3 {
        background-image: url("./Resources/proj-zawolcze/stawy_logotype_mobile.jpg");
        height: 478px;
      }

      #view-tile-9 {
        background-image: url("./Resources/proj-zawolcze/stawy_produkty_mobile.jpg");
        height: 478px;
      }

      #view-tile-15 {
        background-image: url("./Resources/proj-zawolcze/398x478_komp.jpg");
        height: 478px;
      }

      #view-tile-16 {
        height: 478px;
      }
    }

    .animations {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box3"
        "box4 box4"
        "box5 box5"
        "box6 box6"
        "box7 box7";

      #view-tile-1,
      #view-tile-3,
      #view-tile-4 {
        height: 435px;
      }
      #view-tile-5,
      #view-tile-6,
      #view-tile-7 {
        height: 460px;
      }
    }

    .drawings-gallery {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box4"
        "box6 box6"
        "box5 box7"
        "box8 box9"
        "box10 box11"
        "box12 box13"
        "box14 box15"
        "box16 box17"
        "box18 box20"
        "box19 box19";

      #view-tile-1 {
        height: 317px;
      }

      #view-tile-6 {
        height: 316px;
      }
    }

    .proj-magazyn {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box3"
        "box4 box4"
        "box5 box6"
        "box8 box8"
        "box7 box10"
        "box9 box9"
        "box11 box11";

      #view-tile-1 {
        height: 478px;
        background-image: url("./Resources/proj-magazyn/mw_812_mobile.jpg");
      }

      #view-tile-3 {
        height: 364px;
      }
      #view-tile-4 {
        background-image: url("./Resources/proj-magazyn/mw_02_812_mobile.jpg");
        height: 478px;
      }

      #view-tile-11 {
        background-image: url("./Resources/proj-magazyn/mw_04_812_mobile.jpg");
        height: 478px;
      }
    }

    .proj-farcar {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box4"
        "box5 box7"
        "box6 box6"
        "box8 box9"
        "box10 box11"
        "box12 box13"
        "box14 box15"
        "box16 box17";

      #view-tile-1 {
        height: 478px;
      }

      #view-tile-17 {
        background-image: url("./Resources/proj-farcar/321_go_398x478.jpg");
        height: 478px;
      }
    }

    .proj-farba {
      grid-template-columns: repeat(2, 398px);
      grid-template-areas:
        "box1 box1"
        "box2 box2"
        "box3 box4"
        "box6 box6"
        "box5 box7"
        "box8 box9"
        "box10 box10"
        "box11 box11"
        "box12 box12"
        "box13 box13"
        "box14 box14";

      #view-tile-1 {
        height: 362px;
      }
      #view-tile-6,
      #view-tile-10 {
        height: 433px;
      }
      #view-tile-12 {
        background-image: url("./Resources/proj-farba/sniezka_812x478.jpg");
        height: 478px;
      }
    }
  }
}

@media (max-width: 830px) {
  header {
    top: 0;
    .mailbox {
      display: none;
    }
    .header-box {
      .visible {
        width: 260px;
      }
    }

    .header-container {
      .header-box {
        #menu-btn {
          display: none;
        }
        #menu-btn-mobile {
          height: 20px;
          width: 30px;
          display: block;

          #hamburger {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            .top-bread,
            .meat,
            .bottom-bread {
              transition: all 0.3s;
            }
          }

          .white {
            .top-bread,
            .meat,
            .bottom-bread {
              width: 100%;
              height: 2px;
              background-color: white;
            }
          }

          .black {
            .top-bread,
            .bottom-bread {
              width: 100%;
              height: 2px;
              background-color: black;
            }
            .top-bread {
              transform: translate(0, 9px) rotate(-45deg);
            }
            .bottom-bread {
              transform: translate(0, -9px) rotate(45deg);
            }
          }
        }

        .black-text {
          #hamburger {
            .top-bread,
            .meat,
            .bottom-bread {
              background-color: black;
            }
          }
        }
      }

      .header-background {
        #menu-btn-mobile {
          #hamburger {
            .top-bread,
            .meat,
            .bottom-bread {
              background-color: black;
            }
          }
        }
      }
    }
  }

  #home-page {
    #hero {
      height: 70vh;
      div:nth-child(1),
      div:nth-child(2) {
        display: none;
      }
      div:nth-child(3) {
        display: block;
      }
      margin-top: 30px;
    }
    .scroll-btn-box {
      width: 375px;
    }
    #home-page-container {
      width: 375px;
      h1 {
        font-size: 30px;
        width: 100%;
      }
      #portfolio-container {
        grid-template-columns: repeat(1, 375px);
        grid-template-areas:
          "box1"
          "box1"
          "box2"
          "box2"
          "box6"
          "box6"
          "box4"
          "box4"
          "box5"
          "box5"
          "box3"
          "box3"
          "box7"
          "box7"
          "box8"
          "box8"
          "box9"
          "box9"
          "box10"
          "box10"
          "box11"
          "box11"
          "box12"
          "box13"
          "box13";

        #box-4 {
          background-image: url("./Resources/proj-animation/kafel_animacje_mobile.gif");
        }
        #box-6 {
          .project-box-content {
            background-image: url("./Resources/proj-drawings/kafel_rysunki.jpg");
          }
          .project-box-content-mask {
            background-color: rgba(0, 0, 0, 0);
            h3 {
              margin-left: 0px;
              margin-bottom: 0px;
              span {
                visibility: visible;
              }
            }
          }
        }

        #box-7 {
          height: auto;
          p {
            span:nth-child(1) {
              font-size: 40px;
              letter-spacing: 3px;
            }
            span:nth-child(2) {
              font-weight: $font-weight-bold;
              font-size: 25px;
            }
          }

          p {
            font-size: 25px;
          }
        }

        #box-11 {
          .project-box-content {
            background-image: url("./Resources/proj-farba/plakat_energiczna.jpg");
          }
        }

        #box-12 {
          div {
            p {
              font-size: $font-size-regular-mobile;
            }
            span {
              font-size: $font-size-regular-mobile - 2px;
            }
          }
        }

        #box-13 {
          .bolded-row {
            font-weight: $font-weight-bold;
            font-size: $font-size-regular;
          }
          .detail-row {
            font-size: $font-size-small;
            font-weight: $font-weight-light;
            p {
              padding-left: 5px;
            }
          }
        }

        .exp {
          opacity: 0;
        }

        .exp-animated {
          opacity: 1;

          .line {
            animation: exp-animation-slider;
            animation-duration: 1.2s;
            animation-iteration-count: 1;
          }

          .circle,
          .circle-2,
          .circle-3 {
            animation: exp-animation-circle;
            animation-duration: 750ms;
            animation-iteration-count: 1;
          }

          .detail-row,
          .bolded-row {
            animation: exp-animation-text;
            animation-duration: 0.5s;
            animation-iteration-count: 1;
          }
        }
      }
    }
  }

  .project-view {
    width: 375px;

    h2 {
      font-size: 40px;
    }

    .project-view-other {
      .project-view-other-container {
        .arrow-left,
        .arrow-right {
          height: 60px;
          width: 50%;
          background-color: rgba(0, 0, 0, 0.7);
          transform: translate(0, 418px);
        }
      }
      .project-view-other-container:hover {
        .arrow-left,
        .arrow-right {
          width: 50%;
        }
      }
    }

    .proj-kaseta {
      grid-template-columns: repeat(2, 179px);

      #view-tile-1 {
        height: 245px;
      }

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-3 {
        height: 222px;
      }

      #view-tile-7 {
        height: 245px;
      }

      #view-tile-4,
      #view-tile-5,
      #view-tile-6,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10,
      #view-tile-11,
      #view-tile-12 {
        height: 241px;
      }
    }

    .chickens-project {
      grid-template-columns: repeat(2, 179px);

      #view-tile-1 {
        height: 245px;
      }

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-3,
      #view-tile-4,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10 {
        height: 222px;
      }

      #view-tile-5,
      #view-tile-6,
      #view-tile-7 {
        height: 241px;
      }
    }

    .drawings-gallery {
      grid-template-columns: repeat(2, 179px);

      #view-tile-1 {
        background-position: left;
        width: 375px;
        height: 288px;
      }

      #view-tile-3,
      #view-tile-4,
      #view-tile-5,
      #view-tile-7,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10,
      #view-tile-11,
      #view-tile-12,
      #view-tile-13,
      #view-tile-14,
      #view-tile-15,
      #view-tile-16,
      #view-tile-17,
      #view-tile-18,
      #view-tile-19,
      #view-tile-20 {
        height: 285px;
      }

      #view-tile-6 {
        height: 160px;
      }
    }

    .animations {
      grid-template-columns: repeat(2, 179px);

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-1,
      #view-tile-3,
      #view-tile-4 {
        height: 200px;
      }

      #view-tile-5,
      #view-tile-6,
      #view-tile-7 {
        height: 210px;
      }
    }

    .proj-zawolcze {
      grid-template-columns: repeat(2, 179px);

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-1,
      #view-tile-3,
      #view-tile-4,
      #view-tile-5,
      #view-tile-6,
      #view-tile-7,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10,
      #view-tile-11,
      #view-tile-12,
      #view-tile-13,
      #view-tile-14,
      #view-tile-15,
      #view-tile-16 {
        height: 210px;
      }
    }

    .proj-magazyn {
      grid-template-columns: repeat(2, 179px);

      #view-tile-1,
      #view-tile-4,
      #view-tile-5,
      #view-tile-6,
      #view-tile-7,
      #view-tile-9,
      #view-tile-10,
      #view-tile-11 {
        height: 210px;
      }
      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-3 {
        height: 167px;
      }
      #view-tile-8 {
        height: 200px;
      }
    }

    .proj-farcar {
      grid-template-columns: repeat(2, 179px);

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-1,
      #view-tile-3,
      #view-tile-4,
      #view-tile-5,
      #view-tile-6,
      #view-tile-7,
      #view-tile-8,
      #view-tile-9,
      #view-tile-10,
      #view-tile-11,
      #view-tile-12,
      #view-tile-13,
      #view-tile-14,
      #view-tile-15,
      #view-tile-16,
      #view-tile-17 {
        height: 210px;
      }

      #view-tile-4 {
        overflow: hidden;
        video {
          height: 215px;
        }
      }
    }

    .proj-farba {
      grid-template-columns: repeat(2, 179px);

      #view-tile-1 {
        height: 167px;
      }

      #view-tile-2 {
        font-size: $font-size-regular-mobile;
        padding: 10px;
      }

      #view-tile-3,
      #view-tile-4,
      #view-tile-5,
      #view-tile-7,
      #view-tile-8,
      #view-tile-9,
      #view-tile-11,
      #view-tile-12 {
        height: 215px;
      }

      #view-tile-6,
      #view-tile-10 {
        height: 200px;
      }
    }
  }

  footer {
    padding: 15px;
    .footer-container {
      .footer-links {
        display: flex;
        flex-direction: column;
        width: 150px;
        a:last-child {
          margin: 0;
        }
        a {
          text-align: left;
        }
      }
    }
    button {
      width: 100px;
    }
    .footer-info {
      width: 150px;
    }
  }
}
