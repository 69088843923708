@import "../Variables/variables";

@keyframes font-color {
  0% {
    color: transparent;
  }

  50% {
    color: #000;
  }

  100% {
    color: #000;
  }
}

button {
  background: none;
  border: none;
  font-weight: $font-weight-semibold;
  color: $font-main-color;
  font-size: $font-size-regular;
  cursor: pointer;
  z-index: 3;
  width: 57px;
}

#menu-btn-mobile {
  display: none;
}

.menu-box {
  position: absolute;
  background-color: #fff;
  width: 398px;
  color: #000;
  right: 0;
  top: 0;
  transition: all 0.3s;

  .menu-section {
    border-top: 1px solid rgb(196, 196, 196);
    h2 {
      padding: 20px;
      cursor: pointer;
      width: 100%;
      color: #000;
    }

    .menu-section-items {
      transition: all 0.3s;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      height: 0;
      p,
      a {
        padding: 10px 20px;
        cursor: pointer;
        width: 100%;
        text-decoration: none;
        font-weight: $font-weight-light;
        color: #000;

        .icon {
          height: 20px;
          width: 20px;
          transform: translateY(4px);
        }
      }

      p:hover,
      a:hover {
        font-weight: $font-weight-bold;
      }
    }

    .projects-opened {
      height: 360px;
    }
  }

  .contact {
    .menu-section-items {
      height: 160px;
    }
  }

  .menu-section:first-child {
    margin-top: 100px;
  }
}

.hidden {
  width: 0;
  overflow: hidden;
}

.visible {
  h2 {
    animation: font-color;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
}

.black-text {
  color: #000;
}
