// font
$font-main-color: #fff;
$font-secondary-color: #000;
$font-family: "Poppins", sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-size-regular: 16px;
$font-size-small: 11px;
$font-size-big: 64px;
$font-size-medium: 30px;
$font-size-medium-subpages: 25px;
$font-size-regular-mobile: 20px;

//components
$background-main-color: rgb(24, 24, 24);
// $background-main-color: rgb(0, 0, 0);
$background-secondary-color: #fff;
$background-tile-color: rgba(0, 0, 0, 0.7);
$header-background-color: rgb(255, 255, 255);
$small-padding: 2em;
$medium-padding: 10em;
$small-margin: 2em;
